<template>
  <div id="">
    <v-card class="" flat light>
      <v-toolbar>
        <v-toolbar-title>Chats</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon class="" @click.native="$emit('chatEvent')" light>
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="mt-n1"></v-divider>

      <v-card-text class="mt-n5">
        <div id="chat-body">
          <v-list two-line light dense v-if="rooms">
            <v-list-item-group v-model="model">
              <v-list-item
                v-for="(room, i) in rooms"
                :key="i"
                @click.native="
                  swapComponent('ChatComponent');
                  $emit('chatSelected', room);
                "
              >
                <v-list-item-avatar>
                  <v-img
                    :src="avatarURl(room.avatar)"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ room.last_message_user.username || room.last_message_user.email  }}
                    {{ room.created_at | chatDateformat }}</v-list-item-title
                  >

                  <v-list-item-subtitle v-text="room.last_message">
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon color="primary">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <div v-else class="mt-5 mx-3 text-center">
            No Chats Available.
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import timezoneMxn from '@/mixins/timezone_mixin'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "Chatlist",
  props: ["swapComponent"],
  mixins: [timezoneMxn],
  data() {
    return {
      message: "2021-04-16T13:00:00.000Z",
      model: "",
      rooms: [
      ],
      pagination: {},
    };
  },
  computed: {
    ...mapGetters(['getSession']),
  },
  mounted() {
    this.getChatList();
  },
  methods: {
    ...mapActions(['performPostActions']),
    async getChatList() {
      
      const payload ={
        "page": this.pagination.page ?? 1,
        "limit": this.pagination.limit ?? 10,
        "customer_id":this.getSession.customer ?  this.getSession.customer.id : null,
        "writer_id": this.getSession.writer ? this.getSession.writer.id : null, 
      }

      const fullPayload = {
        params: payload,
        endpoint: '/room/show-rooms'
      }

      const response = await this.performPostActions(fullPayload);
      this.rooms = response.data.data;
      this.pagination  = response.data.pagination;
    },
    avatarURl(avatar) {
      const url = avatar ? avatar : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png';
      return url;
    },
    
  },
};
</script>
<style scoped></style>
