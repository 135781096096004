<template>
  <div id="">
    <v-card flat light>
      <v-toolbar flat>
        <v-btn icon light @click="swapComponent('Chatlist')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div class="d-flex">
          <v-list-item v-for="(member, index) in members" :key="index" class="px-1">
            <v-badge 
              bottom 
              :color="member.is_online ? 'success' : 'pink accent-4'"
              dot 
              offset-x="10" 
              offset-y="10"
            >
              <v-avatar class="" size="38">
                <v-img :src="avatarURl(member.avatar ? member.avatar : '' )"></v-img>
              </v-avatar>
            </v-badge>
            <v-list-item-content class="ml-3" v-if="members.length <= 1">
              <v-list-item-title>{{member.nickname}} {{ member.nickname }}</v-list-item-title>
              <v-list-item-subtitle>{{ member.is_online ? 'Online' : 'Offline'}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon class="mr-2" @click.prevent="$emit('chatEvent', false)" light>
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <hr>
      
      <v-card flat id="chat-body">
        <v-card-text>
          <!-- <v-card> -->
          <div v-if="messages.length === 0" class="mt-5 mx-3 text-center">
            No messages yet
          </div>

          <div v-else>
            <div class="mb-4" v-for="(message, index) in messages" :key="index">
              <div class="d-flex" v-if="message.sender_id !== loggedInUser">
                <div class="d-flex align-start flex-column">
                  <v-list-item-avatar size="28">
                    <v-icon v-if="message.sender_id === 3">mdi-cog</v-icon>

                    <v-img v-else :src="avatarURl( message.sender && message.sender.avatar ? message.sender.avatar : null)"></v-img>
                  </v-list-item-avatar>
                </div>

                <v-card flat outlined class="pointer">
                  <v-card-text class="pb-0">
                    <span>
                      {{ message.text }}
                    </span>
                  </v-card-text>
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <span class="font-small">{{ formatHourTime(message.created_at) }}</span>
                  </v-card-actions>
                </v-card> 
              </div>

              <div class="pointer-right mt-5 d-flex  flex-row-reverse" v-if="message.sender_id === loggedInUser">
                <v-card flat outlined color="primary" dark>
                  <v-card-text class="pb-0">
                    <span>
                      {{ message.text }}
                    </span>
                  </v-card-text>
                  <v-card-actions class="py-0">
                    <v-spacer></v-spacer>
                    <span class="font-small-2">{{ formatHourTime(message.created_at) }}</span>
                  </v-card-actions>
                </v-card>
              </div>
            </div>
          </div>

          <div id="bottomOfArea"></div>
        </v-card-text>
      </v-card>
      <v-card-actions class="footer ml-3">
        <v-text-field
          v-model="message"
          label="Type here to send"
          class="textField"
          light
          clearable
          v-on:keyup.enter="sendMessage"
          :append-outer-icon="message ? 'mdi-send' : 'mdi-microphone'"
          @click:append-outer="sendMessage"
        ></v-text-field>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import timezoneMxn from '@/mixins/timezone_mixin';
import { mapActions, mapGetters, mapMutations } from "vuex";
import connection from '@/socket/utils';

export default {
  name: "ChatComponent",
  props: ["swapComponent", "selectedChat"],
  mixins: [timezoneMxn],
  data() {
    return {
      chatstatus: true,
      members: [
      ],
      chatSocket: null,
      message: "",
      messages: [],
      noMessages: true,
      isConnected: '',
    };
  },
  computed: {
    ...mapGetters(['getChatStatus', 'getSession']),
    loggedInUser() {
      return this.getSession.id;
    }
  },
  watch: {
    selectedChat(val) {
      this.getRoom(val.id)
      this.getMessages(val.id);
    }
  },
  mounted() {
    this.getRoom(this.selectedChat.id)
    this.getMessages(this.selectedChat.id);
    connection.connect();
    this.chatSocket = connection.subscribe(`chat:${this.selectedChat.id}`, this.handleMessage);
  },
  updated() {
    var elem = document.getElementById("bottomOfArea");
    elem.scrollIntoView({ behavior: "smooth", block: "end" });
  },
  beforeDestroy() {
    this.chatSocket.close();
  },
  methods: {
    ...mapActions(['performGetActions']),
    ...mapMutations(['setPushNotification']),
    async getRoom(id) {
      const fullPayload = {
        endpoint: `/room/show-members/${id}`
      };
      const response = await this.performGetActions(fullPayload);
      this.members = response[0].members
    },
    async getMessages(id) {
      const payload = {
        page: 1,
        limit: 50,
      }

      const fullPayload = {
        params: payload,
        endpoint: `/room/chats/${id}`
      }

      const response = await this.performGetActions(fullPayload);
      this.messages = response.data.data;
    },
    handleMessage(message) {
      this.messages.push(message);
      // console.log(message.sender_id,  this.loggedInUser, 'habdler message');
      if (message.sender_id !== this.loggedInUser) {
        console.log('handle message');
        this.setPushNotification({
          title: `Message from ${message.sender?.nickname}`,
          body: message.text,
        });
        // if (Notification.permission === 'granted') {

        //   const notification = new Notification(`Message from ${message.sender?.nickname}`, {
        //       body: message.text,
        //       icon: "https://www.iconninja.com/files/926/373/306/link-chain-url-web-permalink-web-address-icon.png",
        //   });
        //   notification.onclick = (event)  => {
        //       event.preventDefault();
        //       notification.close();
        //   }
        // }
        // const audio = new Audio(require('../assets/notification.mp3'));
        // audio.play();
      }
    },
    handleNotification(notification) {
      console.log(notification);
    },
    sendMessage() {
      if (!this.message) {
        return;
      }
      const message = {
        text: this.message,
        sender_id: this.getSession.id,
        room_id: this.selectedChat.id,
        theme: 'DEFAULT'
      }

      try{
        this.chatSocket.emit('message', message);
        this.message = '';
      } catch (error) {
        console.log(error);
        this.sendMessage;
      }
    },
    avatarURl(avatar) {
      const url = avatar ? avatar : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png';
      return url;
    }
  },
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
}
.textField {
  width: 300px;
}
.font-small {
  font-size: 10px;
  text-align: center;
  color: #909399;
}

.font-small-2 {
  font-size: 10px;
  text-align: center;
}
.pointer:before{
  content: "";
  position: absolute;
  top: 7px;
  left:-20px;
  z-index: 5;
  border: solid 10px transparent;
  border-right-color: #d2d7e2;
}

.pointer-right:before{
  content: "";
  position: absolute;
  top: 5px;
  right:-20px;
  z-index: 5;
  border: solid 10px transparent;
  border-right-color:red;
}
</style>
