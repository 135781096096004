import { Base64 } from 'js-base64';
import router from "@/router";
import axios from "axios";

export default {
  async initAuth({ commit, state, dispatch }) {
    const token = localStorage.getItem('jwtToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const tokenExpiryStatus = state.tokenExpired;

    if (tokenExpiryStatus) {
      await dispatch('handleErrors', 403, {
        root: true,
      });
      return;
    }

    if (token !== null && token !== '') {
      const partsOfToken = token.split('.');

      const middleString = Base64.decode(partsOfToken[1]);
      const payload = JSON.parse(middleString);
      const userData = payload.data;
      commit('setSession', userData);
    }
    commit('setAccessToken', token);
    commit('setRefreshToken', refreshToken);
  },
  
  async handleErrors({ commit, dispatch }, error) {
    switch (error) {
      case 403:
        await dispatch("refreshAuthToken");
        break;
      case 204:
        commit("setTokenExpiryStatus", true);
        commit("setLoginErrors", " You have been successfully logged out.");
        await dispatch("clearCache");
        router.push("/auth/login");
        break;
      default:
    }
  },
  
  clearCache({ commit }) {
    commit("setAccessToken", null);
    commit("setRefreshToken", null);
    localStorage.clear();
  },

  async custom_headers({ state }, fileUpload) {
    const authToken = `Bearer ${state.token}`;
    const param = {
      headers: {
        'Content-Type': fileUpload ? 'multipart/form-data' : 'application/json',
        Accept: 'application/json',
        Authorization: authToken,
      },
    };
    return param;
  },

  async axiosPostRequest({ dispatch }, payload) {
    const { endpoint } = payload;
    const { fileUpload } = payload;
       
    const config = await dispatch('custom_headers', fileUpload);

    const url = `${process.env.VUE_APP_BASE_URL}${endpoint}`;
    try {
      const response = await axios.post(`${url}`, payload.params, config);
      return response;
    } catch (error) {
      dispatch("setErrorAction", error.response.data);
      await dispatch("handleErrors", error.response.status);
      return error.response;
    }
  },
  async axiosPutRequest({ dispatch }, payload) {
    const { endpoint } = payload;
    const { fileUpload } = payload;

    const config =  await dispatch('custom_headers', fileUpload);


    const url = `${process.env.VUE_APP_BASE_URL}${endpoint}`;
    try {
      const response = await axios.put(`${url}`, payload.params, config);
      return response;
    } catch (error) {
      dispatch("setErrorAction", error.response.data);
      await dispatch("handleErrors", error.response.status);
      return error.response;
    }
  },
  async axiosDeleteRequest({ dispatch }, payload) {
    const { endpoint } = payload;
    const { fileUpload } = payload;
    
    const config = await dispatch('custom_headers', fileUpload);

    const url = `${process.env.VUE_APP_BASE_URL}${endpoint}`;
    try {
      const response = await axios.delete(`${url}`, config);
      return response;
    } catch (error) {
      await dispatch("handleErrors", error.response.status);
      return error.response;
    }
  },
  setErrorAction({ commit }, payload) {
    let errors = {};
    payload.forEach(el => {
      errors[el.field] = el.message;
    });
    commit("setErrors", errors);
  },
  async axiosGetRequest({ dispatch, commit }, payload) {
    const { endpoint } = payload;
    const { params } = payload;
    const url = `${process.env.VUE_APP_BASE_URL}${endpoint}`;

    const headers = await dispatch('custom_headers');
    const values = {
      params,
      headers: headers.headers,
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const value in values) {
      if (values[value] === null || values[value] === undefined) {
        delete values[value];
      }
    }
    try {
      const response = await axios.get(url, values);
      return response;
    } catch (error) {
      await dispatch("handleErrors", error.response.status);
      commit("setErrors", error.response.data);
      return error.response;
    }
  },
  async performPostActions({ dispatch }, payload) {
    try {
      const res = await dispatch("axiosPostRequest", payload);
      return res.data;
    } catch (error) {
      return error.response;
    }
  },
  async uploadImage({ dispatch }, payload) {
    payload.fileUpload = true;
    try {
      const res = await dispatch('axiosPostRequest', payload);
      return res.data;
    } catch (error) {
      return error.response;
    }
  },
  async performUpdateActions({ dispatch }, payload) {
    try {
      const res = await dispatch("axiosPutRequest", payload);
      return res;
    } catch (error) {
      return error.response;
    }
  },
  async performPutActions({ dispatch }, payload) {
    try {
      const res = await dispatch("axiosPutRequest", payload);
      return res.data;
    } catch (error) {
      return error.response;
    }
  },
  async performGetActions({ dispatch }, payload) {
    try {
      const res = await dispatch("axiosGetRequest", payload);
      return res.data;
    } catch (error) {
      return error.response;
    }
  },
  async refreshAuthToken({ commit, dispatch }) {
    const url = `${process.env.VUE_APP_BASE_URL}/token`;
    const refreshToken = localStorage.getItem("refreshToken");
    const params = { refresh_token: refreshToken };
    const payload = JSON.stringify(params);
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      const response = await axios.post(`${url}`, payload, config);
      const token = response.data.token;
      if (token !== null && token !== '') {
        const partsOfToken = token.split('.');
  
        const middleString = Base64.decode(partsOfToken[1]);
        const payload = JSON.parse(middleString);
        const userData = payload.data;
        commit('setSession', userData);
      }
      localStorage.setItem("jwtToken", token);
      commit("setAccessToken", token);
      commit("setSessionRefreshed", true);
      return response;
    } catch (error) {
      await dispatch("handleErrors", 204);
      await dispatch("clearCache");
    }
  },
  async performDeleteActions({ dispatch }, payload) {
    try {
      const res = await dispatch("axiosDeleteRequest", payload);
      return res;
    } catch (error) {
      return error.response;
    }
  },
  async getBalance({dispatch, commit}) {
    const payload = {
      endpoint: `/get-summary`
    }
    try {
      const res = await dispatch('axiosGetRequest', payload);
      commit('setRunningBalance', res.data.data.balance);
      commit('setNotification', res.data.data.notification);
      return res
    } catch (error) {
      return error.response;
    }
  },
};
