<template>
  <div>
    <topBar />
    <v-container>
      <div class="mt-5 col-12 col-md-4 mx-auto">
        <router-view></router-view>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SignView",
  components: {
    topBar: () => import("@/components/topBar")
  },
  data() {
    return {
      show: true
    };
  }
};
</script>

<style scoped>
.ant {
  background: #273142;
}
.card-header {
  height: 70px;
  border-bottom: 2px solid #dbd7d779;
}
</style>
