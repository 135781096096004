import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

Vue.use(Vuex);

const state = {
  token: localStorage.getItem('jwtToken'),
  authenticationStatus: null,
  sessionData: null,
  refreshToken: null,
  loginErrors: null,
  tokenExpired: false,
  chatStatus: {
    status: false,
    room: null,
  },
  base_url: "http://localhost:3333",
  errors: {},
  orderRequirementError: false,
  currentComponent: null,
  uploadFile: [],
  uploadedFiles: [],
  orderDetail: null,
  orderList: [],
  uploadedAnswer: [],
  isOnline: null,
  reload: false,
  notification: 0,
  runningBalance: 0,
  directory: null,
  amount: null,
  orderObject: null,
  pushNotification: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
