<template>
  <v-app>
    <sidebar v-if="isAuthenticated && userRole === 1 && !isOrderRoute"> </sidebar>
    <writerNavDraw v-if="isAuthenticated && userRole === 2" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'App',
  components: {
    sidebar: () => import("@/components/core/NavigationDrawer"),
    writerNavDraw: () => import("@/components/core/writerNavDraw"),
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(['getSession', 'isAuthenticated', 'getPushNotification']),
    userRole() {
      const role = this.getSession ? this.getSession.user_type : null;
      return role;
    },
    isOrderRoute() {
      const res =  this.$route.name === 'customer' || this.$route.name === 'customerDraft';
      return res;
    }
  },
  watch: {
    getPushNotification(val) {
      console.log(val, 'not getting message');
      if (Notification.permission === 'granted') {
        const notification = new Notification(val.title, {
          body: val.body,
          icon: "https://www.iconninja.com/files/926/373/306/link-chain-url-web-permalink-web-address-icon.png",
        });
        notification.onclick = (event)  => {
            event.preventDefault();
            notification.close();
        }
      }
      const audio = new Audio(require('./assets/notification.mp3'));
      audio.play();
    },
  },
  async mounted() {
    await Notification.requestPermission();
    this.getDir();
  },
  methods: {
    ...mapActions(['performGetActions']),
    ...mapMutations(['setDirectory']),
    async getDir() {
      const fullPayload = {
        endpoint: '/directory'
      };
      const response = await this.performGetActions(fullPayload);
      this.setDirectory(response);
    }
  }
};
</script>

<style>
@import "assets/styles/core.css";
</style>