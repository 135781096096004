
import Ws from '@adonisjs/websocket-client'

export class SocketConnection {
  connect () {
    const domain = new URL(process.env.VUE_APP_BASE_URL);
    this.ws = Ws(process.env.NODE_ENV === 'production' ? `wss://${domain.hostname}` : `ws://localhost:3333` )
    
    this.ws.connect();

    this.ws.on('open', () => {
      console.log('Connection initialized');
    });

    this.ws.on('close', () => {
      console.log('Connection closed')
    });

    return this
  }

  subscribe (channel, handler) {
    if (!this.ws) {
      setTimeout(() => this.subscribe(channel), 1000)
    } else {
      const result = this.ws.subscribe(channel);
      console.log('channel subscribed: ', channel)
      result.on('message', message => {
        handler(message)
      });
      result.on('error', (error) => {
        console.error(error)
      });

      return result
    }
  }
}

export default new SocketConnection()
