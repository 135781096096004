import Vue from "vue";
import App from "./App.vue";
import DatetimePicker from 'vuetify-datetime-picker';

import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import store from "@/store";
import FlagIcon from "vue-flag-icon";
import VueEllipsis from "vue-ellipsis";
import router from './router'
// import Ws from '@adonisjs/websocket-client'

// const domain = new URL(process.env.VUE_APP_BASE_URL);
// const ws = Ws(process.env.NODE_ENV === 'production' ? `wss://${domain.hostname}` : `ws://localhost:3333`);

const orderNotification = new Audio('@/assets/notifications/order-notification.wav')
const popNotification = new Audio('@/assets/notifications/pop-notification.wav')
// Vue.prototype.$ws = ws;
Vue.prototype.$orderNotification = orderNotification;
Vue.prototype.$popNotification = popNotification;

Vue.use(DatetimePicker)
Vue.use(VueEllipsis);
Vue.use(FlagIcon);

Vue.use(VueTelInputVuetify, {
  vuetify
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount("#app");
