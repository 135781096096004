<template>
  <div>
    <topBar v-if="userRole !== 1" />
    <div class="main-wrap">
      <v-container class="backdrop mt-5" :class="{'backdropfull': chatstatus}">
        <router-view />
      </v-container>

      <div class="curtain" :class="{'curtain-none': !chatstatus}">
        <div
          :is="currentComponent"
          :swap-component="swapComponent"
          :selected-chat="selectedChat"
          @chatSelected="chatSelected"
          @chatEvent="chatEvent"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatComponent from "./ChatComponent";
import Chatlist from "./Chatlist";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Dashboard",
  components: {
    topBar: () => import("@/components/topBar"),
    ChatComponent: ChatComponent,
    Chatlist: Chatlist
  },
  data() {
    return {
      msg: "hey message",
      chatstatus: false,
      currentComponent: "Chatlist",
      selectedChat: {
        id: "1",
        name: "victor Misiko",
        last_message: "I called you on this, right?",
      }
    };
  },
  computed: {
    ...mapGetters(["getChatStatus", 'getSession', 'isAuthenticated']),
    userRole() {
      const role = this.getSession ? this.getSession.user_type : null;
      return role;
    },
  },
  watch: {
    getChatStatus(data) {
      // const res = Object.prototype.hasOwnProperty.call(data, 'id')
      // // if (res) {
      // //   return;
      // // }
      this.chatopen(data);
    }
  },
  methods: {
    ...mapMutations(['setChatStatus']),
    chatEvent() {
      const data = {
        status: !this.getChatStatus.status,
        room: null,
      }
      this.setChatStatus(data);
    },
    chatopen(data) {
      this.chatstatus = data.status;
      this.currentComponent = data.room ? "ChatComponent" : "Chatlist";
      this.selectedChat = data.room ? data.room : null;
    },
    swapComponent: function(component) {
      this.currentComponent = component;
    },
    chatSelected(chat) {
      this.selectedChat = chat;
    },
  }
};
</script>

<style scoped>
.main-wrap {
  position: relative;
}

.backdrop {
  position: fixed;
  height: 100vh;
  width: 75%;
  overflow-y: scroll;
}

.backdropfull {
  position: fixed;
  height: 100vh;
  width: 95ch;
  overflow-y: scroll;
}

.backdrop::-webkit-scrollbar {
  display: none;
}

.backdropfull::-webkit-scrollbar {
  display: none;
}

.curtain {
  display: block;
  height: 98vh;
  width: 370px;
  min-width: 370px;
  background-color: #ffff;
  position: absolute;
  z-index: 9999;
  top: 0px;
  right: 0px;
  color: black;
}

.curtain-none {
  display: none;
}

.icon {
  width: 70px;
  text-align: left;
}
.Field {
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  border: 2px solid #a2a3a5;
  border-radius: 4px;
}

.inputContainer i {
  position: absolute;
}
</style>
