export default {
  isAuthenticated: (state) => state.token !== null,
  getSession: state => state.sessionData,
  getTokenExpiryStatus: (state) => state.tokenExpired,
  getSessionRefreshed: (state) => state.sessionRefreshed,
  getChatStatus: state => state.chatStatus,
  getErrors: state => state.errors,
  getOrderRequirementError: state => state.orderRequirementError,
  getCurrentComponent: state => state.currentComponent,
  getUploadFile: state => state.uploadFile,
  getUploadedFile: state => state.uploadedFiles,
  getUploadedAnswer: state => state.uploadedAnswer,
  getOrderDetail: state => state.orderDetail,
  getOrderList: state => state.orderList,
  getIsOnline: state => state.isOnline,
  getReload: state => state.reload,
  getNotification: state => state.notification,
  getRunningBalance: state => state.runningBalance,
  getDirectory: state => state.directory,
  getAmount: state => state.amount,
  getOrderObject: state => state.orderObject,
  getPushNotification: state => state.pushNotification,
};
