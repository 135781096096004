import axios from 'axios';
import moment from 'moment';

const timezone = {
  data() {
    return {
      redText: false ,
      customerTime: '',
    }
  },
  filters: {
    chatDateformat(value) {
      const local = moment.utc(value).local().format();
      const date1 = new Date();
      const date2 = moment(date1);
      const months = date2.diff(local, 'months');
      const days = date2.diff(local, 'days');
        //   const years = date2.diff(local, 'weeks');
      const hours = date2.diff(local, 'hours');
      const minutes = date2.diff(local, 'minutes');
      const seconds = date2.diff(local, 'seconds');
    
      let result = '';
      switch (true) {
        case months > 0:
          result = `${months} months Ago`;
          break;
        case months < 0:
          result = `${-months} Months Remaining`;
          break;
        case days > 0:
          result = `${days} Days Ago`;
          break;
        case days < 0:
          result = `${-days} Days Remaining`;
          break;
        case hours > 0:
          result = `${hours} Hours Ago`;
          break;
        case hours < 0:
          result = `${-hours} Hours Remaining`;
          break;
        case minutes > 0:
          result = `${-minutes} Minutes Ago`;
          break;
        case minutes < 0:
          result = `${-minutes} Minutes Remaining`;
          break;
        case seconds > 0:
          result = `${-seconds} seconds Ago`;
          break;
        case seconds < 0:
          result = `${-seconds} seconds Remaining`;
          break;
        default:
          break;
        }
      return result
    
    },
    dateformat(value) {
      const local = moment.utc(value).local().format();
      const date1 = new Date();
      const date2 = moment(date1);
      const months = date2.diff(local, 'months');
      const days = date2.diff(local, 'days');
      // const years = date2.diff(local, 'weeks');
      const hours = date2.diff(local, 'hours');
      const minutes = date2.diff(local, 'minutes');
      const seconds = date2.diff(local, 'seconds');
    
        
      let result = '';
      switch (true) {
        case months > 0:
          result = `${months} months Passed`;
          this.redText = true
          break;
        case months < 0:
          result = `${months} days Remaining`;
          break;
        case days > 0:
          result = `${days} days Passed`;
          break;
        case days < 0:
          result = `${-days} days Remaining`;
            break;
        case hours > 0:
          result = `${hours} Hours Passed`;
          break;
        case hours < 0:
          result = `${-hours} Hours Remaining`;
          break;
        case minutes > 0:
          result = `${minutes} Minutes Passed`;
          break;
        case minutes < 0:
          result = `${-minutes} Minutes Remaining`;
          break;
        case seconds > 0:
          result = `${seconds} seconds Passed`;
          break;
        case seconds < 0:
          result = `${-seconds} seconds Remaining`;
          break;
        default:
          break;
        }
      return result
    },
  },
  computed: {
    currentTime() {
      const res = moment().format("h:mm a")
      return res;
    },
    customerCurrentTime() {
      return this.formatCustomerCurrentTime(this.customerTime);
    }
  },
  methods: {
    convertToLocalTime(date) {
      const local = moment.utc(date).local().format();
      return local;
    },
    formatOrderDate(value) {
      const local = moment.utc(value).local().format();
      const date1 = new Date();
      const date2 = moment(date1);
      const months = date2.diff(local, 'months');
      const days = date2.diff(local, 'days');
      // const years = date2.diff(local, 'weeks');
      const hours = date2.diff(local, 'hours');
      const minutes = date2.diff(local, 'minutes');
      const seconds = date2.diff(local, 'seconds');
    
        
      let result = '';
      switch (true) {
        case months > 0:
          result = `${months} months Passed`;
          this.redText = true
          break;
        case months < 0:
          result = `${months} days Remaining`;
          
          break;
        case days > 0:
          result = `${days} days Passed`;
          this.redText = true
          break;
        case days < 0:
          result = `${-days} days Remaining`;
            break;
        case hours > 0:
          result = `${hours} Hours Passed`;
          this.redText = true
          break;
        case hours < 0:
          result = `${-hours} Hours Remaining`;
          break;
        case minutes > 0:
          result = `${minutes} Minutes Passed`;
          this.redText = true
          break;
        case minutes < 0:
          result = `${-minutes} Minutes Remaining`;
          break;
        case seconds > 0:
          result = `${seconds} seconds Passed`;
          this.redText = true
          break;
        case seconds < 0:
          result = `${-seconds} seconds Remaining`;
          break;
        default:
          break;
        }
      return result
    },
    formatDateMonth(value) {
      const result = moment.utc(value).format("MMMM Do YYYY, h:mm a");
      return result;
    },
    formatDefault(value) {
      const local = moment.utc(value).format("MMMM Do YYYY")
      return local;
    },
    timeErrorStatus(time) {
      const date = new Date();
      return date > Date.parse(time);
    },
    formatHourTime(value) {
      return moment.utc(value).format("h:mm a")
    },
    formatCustomerCurrentTime(value) {
      return moment(value).format("h:mm a")
    },
    checkTime(date) {
      const currentTime = moment();
      const timeStore = moment(date);

      return currentTime.diff(timeStore, 'h');
    },
    determineDuration(val) {
      const localDate = this.convertToLocalTime(val);
      const formattedDate = moment(localDate).fromNow()
      return formattedDate;
    },
    getCustomerTime() {
      const TIME_API_URL = process.env.VUE_APP_TIME_API;
      axios(`${TIME_API_URL}/${this.order.customer.timezone}`)
        .then((response) => {
          this.customerTime = response.data.utc_datetime;
        })
        .catch(error => error);
    },
  }

}

export default timezone;