import Vue from "vue";
import Router from "vue-router";
import store from '@/store';
import Dashboard from "@/views/Dashboard.vue";
import SignView from "@/views/SignView.vue";


Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "index",
    component: ()=> import('@/views/Home'),
    meta: {
      requiresAuth: false,
      requiresFilters: true,
    },
  },
  {
    path: "/order/draft",
    name: "customer",
    component: () => import("@/modules/Orders"),
    meta: {
      requiresAuth: true,
      requiresFilters: true,
    },
  },
  {
    path: "/order/draft/:id",
    name: "customerDraft",
    component: () => import("@/modules/Orders"),
    meta: {
      requiresAuth: true,
      requiresFilters: true,
    },
  },
  {
    path: "/dashboard/",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiresFilters: true,
    },
    children: [
      {
        path: "orders/",
        component: () => import("@/modules/MyOrders/Myorders"),
        name: "dashboard",
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
        children: [ 
          {
            path: "open",
            component: () => import("@/modules/MyOrders/openOrders"),
            name: "open",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
          },
          {
            path: "draft",
            component: () => import("@/modules/MyOrders/draftOrders"),
            name: "draft",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
          },
          {
            path: "closed",
            component: () => import("@/modules/MyOrders/closedOrders"),
            name: "closed",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
          },
        ],
      },
      {
        path: "order/:id/assign/",
        component: () => import("@/modules/MyOrders/MyBids/assignBid"),
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
        children: [
          {
            path: "",
            component: () => import("@/modules/MyOrders/MyBids/bidList"),
            name: "allBIds"
          },
          {
            path: "bids",
            component: () => import("@/modules/MyOrders/MyBids/bidList"),
            name: "allBids"
          },
          {
            path: "shortlist",
            component: () => import("@/modules/MyOrders/MyBids/bidList"),
            name: "shortListed"
          },
          {
            path: "invitations",
            component: () => import("@/modules/MyOrders/MyBids/bidList"),
            name: "invitations"
          }
        ]
      },
      {
        path: "order/:id/",
        component: () => import("@/modules/MyOrders/orderDetail"),
        name: 'orderDetail',
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
      },
      {
        path: "writers",
        component: () => import("@/modules/Writers/writers"),
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
        children: [
          {
            path: "all",
            component: () => import("@/modules/Writers/allWriters"),
            name: "allWriters",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
          },
          {
            path: "my",
            component: () => import("@/modules/Writers/myWriters"),
            name: "myWriters",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
          },
          {
            path: "blocked",
            component: () => import("@/modules/Writers/components/writersList"),
            name: "blocked",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
          }
        ]
      },

      {
        path: "notifications",
        name: "notifications",
        component: () => import("@/modules/Notifications"),
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
      },
      {
        path: "settings",
        component: () => import("@/modules/Settings/Settings"),
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
      },
      {
        path: "finance/",
        name: "finance",
        component: () => import("@/modules/Balance/Balance"),
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
        children: [
          {
            path: "transaction",
            name: "trasanction",
            component: () => import("@/modules/Balance/components/transactionList"),
          },
          {
            path: "withdrawal",
            name: "withdrawal",
            component: () => import("@/modules/Balance/components/widthrawList"),
          },
          {
            path: "payment-accounts",
            name: "paymentAccounts",
            component: () => import("@/modules/Balance/components/paymentMethod"),
          },
          {
            path: "refunds",
            name: "Refunds",
            component: () => import("@/modules/Balance/components/RefundList"),
          }
        ]
      },
      {
        path: "customer/profile/view",
        name: "customerProfile",
        component: () => import("@/modules/Profile/"),
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
      },
      {
        path: "customer/profile/edit",
        name: "editCustomerProfile",
        component: () => import("@/modules/Profile/editProfile"),
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
      },
    ]
  },
  {
    path: "/writer",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiresFilters: true,
    },
    children: [
      {
        path: "",
        component: () =>
          import("@/modules/writersDashboard/available/availableOrders"),
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
        children: [
          {
            path: "",
            name: "allAvailable",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
            component: () =>
              import("@/modules/writersDashboard/available/allAvailable")
          },
          {
            path: "all",
            name: "all",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
            component: () =>
              import("@/modules/writersDashboard/available/allAvailable")
          },
          {
            path: "invited",
            name: "invited",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
            component: () =>
              import("@/modules/writersDashboard/available/allAvailable")
          },
          {
            path: "hidden",
            name: "hidden",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
            component: () =>
              import("@/modules/writersDashboard/available/allAvailable")
          },
          {
            path: "in-progress",
            name: "in-progress",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
            component: () =>
              import("@/modules/writersDashboard/InProgress")
          },
          {
            path: "Bidding",
            name: "bidding",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
            component: () =>
              import("@/modules/writersDashboard/bidding")
          },
          {
            path: "completed",
            name: "completed",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
            component: () =>
              import("@/modules/writersDashboard/completed")
          },
          {
            path: "cancelled",
            name: "cancelled",
            meta: {
              requiresAuth: true,
              requiresFilters: true,
            },
            component: () =>
              import("@/modules/writersDashboard/cancelled")
          }
        ]
      },
      {
        path: "/orderDetail/:id/bid",
        name: "orderDetailBid",
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
        component: () =>
          import("@/modules/writersDashboard/orderDetail/orderDetail")
      },
      {
        path: "/profile/view",
        name: "writerProfileView",
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
        component: () =>
          import("@/modules/Profile/writerProfile")
      },
      {
        path: "/profile/edit",
        name: "writerProfileedit",
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
        component: () =>
          import("@/modules/Profile/writerProfile/components/editProfile")
      },
    ]
  },
  {
    path: "/auth/",
    component: SignView,
    children: [
      {
        path: "",
        name: "auth",
        component: () => import("@/components/auth/Login")
      },
      {
        path: "login",
        name: "login",
        component: () => import("@/components/auth/Login")
      },
      {
        path: "register",
        name: "register",
        component: () => import("@/components/auth/Signup")
      },
      {
        path: "writer-register",
        name: "WriterRegister",
        component: () => import("@/components/auth/writerSignup")
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () => import("@/components/auth/Forgotpassword")
      },
      {
        path: "reset-password/:token/:email",
        name: "resetPassword",
        component: () => import('@/components/auth/ResetPassword'),
        meta: {
          requiresAuth: false,
          requiresFilters: true,
        },
      },
    ]
  }
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch('initAuth');
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next('/auth/login');
  } else {
    next();
  }
});

export default router;
