export default {
  updateToken(state, newToken) {
    localStorage.setItem("token", newToken);
    state.jwt = newToken;
  },
  setTokenExpiryStatus(state, val) {
    state.tokenExpired = val;
  },
  setSessionRefreshed(state, val) {
    state.sessionRefreshed = val;
  },
  removeToken(state) {
    localStorage.removeItem("t");
    state.jwt = null;
    state.authUser = {};
    state.isAuthenticated = false;
  },
  setChatStatus(state, newstatus) {
    const res = Object.prototype.hasOwnProperty.call(newstatus, 'id')
      if (res) {
        return;
      }
    state.chatStatus = newstatus;
  },
  setAccessToken(state, val) {
    state.token = val;
  },
  setRefreshToken(state, val) {
    state.refreshToken = val;
  },
  setSession(state, val) {
    state.sessionData = val;
  },
  setErrors(state, val) {
    state.errors = val;
  },
  setOrderRequirementError(state, val) {
    state.orderRequirementError = val;
  },
  setCurrentComponent( state, val) {
    state.currentComponent = val;
  },
  setUploadFile( state, files) {
    files.forEach( (file) => {
      state.uploadFile.push(file);
    })
  },
  setUnsetUpload( state, files) {
    state.uploadFile = files;
  },
  setRemoveFile( state, val) {
    state.uploadFile.splice(val, 1);
  },
  setUploadedFile( state, file) {
    state.uploadedFiles.push(file);
  },
  setMountUploadedFile( state, files){
    state.uploadedFiles = files;
  },
  setRemoveUploadedFile( state, key) {
    state.uploadedFiles.splice(key, 1);
  },
  setOrderDetail( state, val) {
    state.orderDetail = val;
  },
  setOrderList( state, val) {
    state.orderList= val;
  },
  setUploadedAnswer( state, files) {
    files.forEach( (file) => {
      state.uploadedAnswer.push(file);
    })
  },
  setMountUploadedAnswer( state, files) {
    state.uploadedAnswer = files
  },
  setRemoveUploadedAnswer( state, key) {
    state.uploadedAnswer.splice(key, 1);
  },
  setIsOnline(state, val) {
    state.isOnline = val;
  },
  setReload(state, val) {
    state.reload = val;
  },
  setPushNotification(state, val) {
    state.pushNotification = val;
  },
  setNotification(state, val) {
    state.notification = val;
  },
  setWholeNotification(state, val) {
    state.notification = parseInt(state.notification) + parseInt(val);
  },
  setRunningBalance(state, val) {
    state.runningBalance = val;
  },
  setDirectory(state, val) {
    state.directory = val;
  },
  setAmount(state, val) {
    state.amount = val;
  },
  setOrderObject(state, val) {
    state.orderObject = val;
  },
};
